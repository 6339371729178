$background-colour: #edebd1;
$foreground-colour: #211b1b;
$accent-colour: #522c22;

@font-face {
  font-family: "font";
  src: url("./../public/fonts/Simple Print.ttf");
}

body {
  background-color: $background-colour;
  color: $foreground-colour;
  margin: 0;
  height: 100vh;
  font-family: "font";
  font-size: 35px;
}

#root {
  height: 100%;
}

h1 {
  font-weight: 500;
}

.noscroll {
  overflow: hidden;
}

.root {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  .header {
    h1 {
      font-size: 120px;
      margin-bottom: 20px;
      margin-top: 30px;
    }

    flex: 0 1 auto;
    margin-bottom: 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .content {
    margin-left: 12px; // ???
    flex: 1 1 auto;
    width: calc(100% - 12px);
    display: grid;
  }

  .impressum {
    flex: 0 1 auto;
    margin-top: 40px;
    padding-bottom: 10px;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    white-space: pre;

    span {
      white-space: nowrap;
    }
  }
}

#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($background-colour, 0.97);
  z-index: 2;
  overflow: auto;

  .details {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;
    padding: 32px;
    box-sizing: border-box;

    img,
    video {
      justify-self: center;
      max-height: calc(100vh - 64px);
      max-width: 100%;
    }

    div {
      h1 {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      padding-top: 150px;
      padding-left: 20px;
    }
  }
}

a {
  color: $foreground-colour;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.navigation {
  text-decoration: none;

  margin-left: 5px;
  margin-right: 5px;

  &.active {
    border-bottom: 5px solid $accent-colour;
  }
}

.image-box {
  position: relative;
  overflow: hidden;
  display: block;

  .thumbnail {
    display: block;
    transition: all 0.3s;
    width: 100%;

    &:hover {
      transform: scale(1.1);
    }
  }

  .show-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.8;
  }

  .hide-play-button {
    display: none;
  }
}

.kontakt {
  max-width: 1000px;
  justify-self: center;

  .highlight {
    font-style: italic;
  }

  .formular {
    display: flex;
    flex-direction: column;
    gap: 10px;

    #email {
      max-width: 300px;
      font-size: 16px;
    }

    #content {
      min-height: 200px;
      font-size: 16px;
    }

    #submit {
      max-width: 100px;
    }
  }

  .response {
    margin-top: 20px;
  }
}

.product-navigation {
  position: fixed;
  bottom: 32px;
  right: 32px;

  a {
    text-decoration: none;
  }

  .next-and-previous {
    font-size: 50px;
    font-weight: 700;
    line-height: 0px;
    color: $accent-colour;
    opacity: 0.5;
    transition: all 0.3s;
    margin-left: 20px;

    &:hover {
      opacity: 1;
    }
  }

  .disabled {
    visibility: hidden;
  }
}

.close {
  position: fixed;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: "";
    height: 33px;
    width: 4px;
    background-color: $accent-colour;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
