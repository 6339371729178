.datenschutz {
  max-width: 1000px;
  justify-self: center;

  h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #matomo-opt-out {
    margin-top: 40px;
  }
}
